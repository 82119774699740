<template>
  <div class="background1">
    <div class="loginBox1">
      <!-- 头像区域 -->
      <div class="avatar_box">
        <img src="../assets/icon/标志.jpg" id="login-logo" />
      </div>
      <!-- ak输入区域 -->
      <div class="akInputBox">
        <div class="akText"><span>登录凭证：</span></div>
        <div class="akInput">
          <el-input
            placeholder="请输入登录凭证"
            prefix-icon="el-icon-key"
            v-model="ak"
            type="password"
          >
          </el-input>
        </div>
      </div>
      <!-- 登录按钮区域 -->
      <div class="loginBtnBox">
        <!-- <el-button @click="akReset">重置</el-button> -->
        <el-button type="primary" @click="login()">登录</el-button>
      </div>
      <span class="switchAdminLogin" @click="goToAdminLogin"
        ><i class="el-icon-sort"></i>管理员登录</span
      >
    </div>
    <span class="copyRight">@CopyRight[2021]珠海市司迈科技有限公司 version:1.0.4</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ak: "",
    };
  },
  mounted() {
    if (window.localStorage.getItem("ak") != null) {
      this.ak = window.localStorage.getItem("ak");
    }
  },
  methods: {
    //切换到管理员登录
    goToAdminLogin() {
      this.$router.push("/admin_login");
    },
    //清空输入栏
    akReset() {
      this.ak = "";
    },
    //登录前预验证(服务器)
    async login() {
      if (this.ak != "") {
          const { data: res } = await this.$http.post(
            "/api/v1/admin/station/login/" + this.ak
          );
          console.log(res);
          if (res.code == 200) {
            window.sessionStorage.setItem("token", res.data.token);
            window.localStorage.setItem(
              "stationId",
              res.data.stationInfo.stationId
            );
            window.localStorage.setItem(
              "stationName",
              res.data.stationInfo.name
            );
            window.localStorage.setItem(
              "shortID",
              res.data.stationInfo.shortId
            );
            window.localStorage.setItem("ak", this.ak);
            this.$router.replace('/patient_video');
          } else {
            this.$message.error("无效的凭证");
            return;
          }
        // }
      }
    },
  },
};
</script>

<style>
.background1 {
  height: 100%;
  width: 100%;
  background-color: #363636;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: loginApear 0.5s forwards;
}
@keyframes loginApear {
  from {
    background-color: #eee;
  }
  to {
    background-color: #363636;
  }
}
.loginBox1 {
  width: 550px;
  height: 400px;
  background-color: #fff;
  position: relative;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: loginBoxApear 0.5s forwards;
}
@keyframes loginBoxApear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.loginBox1 .avatar_box {
  height: 130px;
  width: 130px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #f29f63;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

.loginBox1 #login-logo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #eee;
}
.akInputBox {
  height: 100px;
  width: 500px;
  transform: translate(0, -30px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.akText {
  font-size: 18px;
  color: #e26a13;
}
.akInput {
  width: 300px;
}
.loginBtnBox {
  width: 200px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 90px;
}
.switchAdminLogin {
  text-decoration-line: underline;
  color: #828282;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  left: 50%;
  bottom: 5px;
  transform: translate(-50%, -50%);
}
</style>